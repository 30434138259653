<template lang="pug">
#yotpo-testimonials-custom-tab(v-if='!showConsentWarning')
  span(class='text-xl') Loading...
div(v-else class='w-full h-full flex items-center justify-center p-4 bg-gray-lighter')
  div(class='text-center')
    | Testimonials are disabled because you haven't consented to non-essential cookies.
    br
    BaseButton(class='mt-8' label='Consent' to='KETCH' size='SMALL') Update Preferences
</template>
<script setup lang="ts">
const { showConsentWarning } = useYotpo()
</script>
